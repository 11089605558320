import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import StrategyCalculator from "utils/StrategyCalculator";
import _ from "lodash";
import { PagedTable } from "components/dataDisplay/PagedTable";

type RowObj = {
  strategy: string;
  strategyId: number;
  investment: string;
  transaction: string;
  event: string;
  entryDate: Date;
  numberOfSecurities: number;
  typeOfSecurity: string;
  cashFlow: number;
  stakeholders:string;
};

export default function TransactionDetails(props: {
  showHistoricalData: boolean;
}) {
  const { showHistoricalData } = props;
  const stakeholders = useSelector(
    (state: RootState) => state.data.stakeholders
  );

  const strategyMappingSettings = useSelector(
    (state: RootState) => state.data.strategyMappingSettings
  );

  const selectedStrategyIds = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );
  
  const selectedDate = useSelector(
    (state: RootState) => state.data.selectedDate
  );

  const translations = useSelector((state: RootState) =>
    state.data.translationMappingSettings.transactions.filter(
      (x) => x.type === "Share"
    )
  );

  const securityClasses = useSelector((state: RootState) =>
    state.data.securityTypeMappingSettings.securityTypes.filter(
      (x) => selectedStrategyIds.some(strategyId => x.strategyIds.includes(strategyId))
    )
  );

  const users= useSelector((state: RootState)=>state.data.users)

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
      }),
    []
  );

  const dateFormatter = useMemo(() => new Intl.DateTimeFormat("nl-BE", {}), []);
  const numberFormatter = useMemo(() => new Intl.NumberFormat("nl-BE", {}), []);

  const columnHelper = createColumnHelper<RowObj>();
  const strategyCalculator = useMemo(
    () => new StrategyCalculator(strategyMappingSettings),
    [strategyMappingSettings]
  );

  const [calculationDate, setCalculationDate] = useState<Date>(new Date());

  useEffect(() => {
    if (showHistoricalData) {
      const calculationDate = selectedDate
        ? new Date(selectedDate)
        : new Date();
      setCalculationDate(calculationDate);
    } else {
      setCalculationDate(new Date());
    }
  }, [selectedDate, showHistoricalData]);

  const translateEvent = (event: string) => {
    return translations.find((x) => x.original === event)?.translated ?? event;
  };

  const translateSecurity = useCallback((strategyId: number, security: string) => {
    return strategyCalculator.translateSecurity(strategyId, securityClasses, security);
  }, [securityClasses, strategyCalculator]);

  const transactionRows = useMemo<RowObj[]>(() => {
    const rows: RowObj[] = [];

    selectedStrategyIds.forEach((strategyId) => {
      const strategyName = strategyCalculator.getStrategyNameById(strategyId);
      const investment =
        strategyCalculator.getCombinedNameByStrategyId(strategyId);

      stakeholders.forEach((st) => {
        // console.log(`Actual value calculation Stakeholder ${st.ownerId}:`);

        st.shares.forEach((share) => {
          if (strategyId !== share.strategyId) {
            return;
          }

          share.shareTransactions
            ?.filter(
              (x) =>
                new Date(x.transactionDate).getTime() <
                calculationDate.getTime()
            )
            ?.forEach((transaction) => {
              rows.push({
                strategy: strategyName,
                strategyId: strategyId,
                investment: investment,
                transaction: transaction.type,
                event:
                  transaction.description?.length > 0
                    ? transaction.description
                    : "-",
                entryDate: new Date(transaction.transactionDate),
                numberOfSecurities: transaction.holder.numberOfSecurities,
                typeOfSecurity:
                  transaction.holder.securityClass?.length > 0
                    ? transaction.holder.securityClass
                    : "-",
                cashFlow: strategyCalculator.calculateCashflow(
                  transaction
                ) * -1,
                stakeholders: strategyCalculator.getStakeHoldersUserNameByStrategyId(stakeholders, users, strategyId),
              });
            });
        });
      });
    });

    return rows.sort((a, b) => b.entryDate.getTime() - a.entryDate.getTime());
  }, [calculationDate, selectedStrategyIds, stakeholders, strategyCalculator]);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const columns = [
    columnHelper.accessor("investment", {
      id: "Investment",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
       {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("transaction", {
      id: "Transaction",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
         {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue() != null ? translateEvent(info.getValue()) : "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("event", {
      id: "Event",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
       {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue() != null ? info.getValue() : "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("entryDate", {
      id: "Entry Date",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
         {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {dateFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("cashFlow", {
      id: "Cashflow",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
       {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("numberOfSecurities", {
      id: "Number of securities",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {numberFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("typeOfSecurity", {
      id: "Type of security",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
         {table.column.id}
        </Text>
      ),
      cell: (info: any) => {
        const rowData = info.row.original; // The entire original data object for this row
        const strategyId = rowData.strategyId; // Another field from the same row
        return (<Text color={textColor} fontSize="sm" fontWeight="400">
          {translateSecurity(strategyId, info.getValue())}
        </Text>);
      },
    }),
    columnHelper.accessor("stakeholders", {
      id: "StakeHolders",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}
        </Text>
      ),
    }),
  ];
  return (
    <PagedTable
      title="Transaction Details"
      columns={columns}
      downloadable={true}
      translations={translations}
      fileName="TransactionDetails.xlsx"
      data={transactionRows}
    />
  );
}
