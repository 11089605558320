import _ from "lodash";
import {
  SecurityTypeMappingSetting,
  Stakeholder,
  StakeholderLoan,
  StakeholderShare,
  StakeholderShareTransaction,
  StrategyMapping,
} from "redux/slices/types";

class StrategyCalculator {
  strategyMappingSettings: StrategyMapping;
  transactionTypesToAddSecurities = [
    "ISSUE_SECURITY",
    "CREATE_SECURITY",
    "TRANSFER_SECURITY",
  ];
  transactionTypesToSubtractSecurities = ["CANCEL_SECURITY"];

  constructor(strategyMappingSettings: StrategyMapping) {
    this.strategyMappingSettings = strategyMappingSettings;
  }

  calculateNumberOfSecuritiesForTransactions = (
    transactions: StakeholderShareTransaction[],
    currentDateTime: Date
  ): number => {
    const amountPerSecClass =
      this.calculateNumberOfSecuritiesForTransactionsBySecClass(
        transactions,
        currentDateTime
      );

    // we just return the sum of securities, it does not matter which security class a transaction has.
    return amountPerSecClass?.reduce((sum, amount) => {
      return amount ? sum + amount.amount : sum;
    }, 0);
  };

  calculateNumberOfSecuritiesForTransactionsBySecClass = (
    transactions: StakeholderShareTransaction[],
    currentDateTime: Date
  ): { secClassName: string; amount: number }[] => {
    const amountToAdd = this.getNumberOfSecuritiesBySecClassAndTransactionType(
      transactions,
      currentDateTime,
      this.transactionTypesToAddSecurities
    );
    const amountToSubtract =
      this.getNumberOfSecuritiesBySecClassAndTransactionType(
        transactions,
        currentDateTime,
        this.transactionTypesToSubtractSecurities
      );

    const totalNumberOfSecuritiesBySecClasses: {
      secClassName: string;
      amount: number;
    }[] = [];

    // These will added (+=) from the amount, per security class name.
    amountToAdd.forEach((amount) => {
      var totalBySecClass = totalNumberOfSecuritiesBySecClasses.find(
        (x) => x?.secClassName === amount?.secClassName
      );
      if (totalBySecClass) {
        totalBySecClass.amount += amount?.amount ?? 0;
      } else {
        const newBySecClass: { secClassName: string; amount: number } = {
          secClassName: amount?.secClassName,
          amount: amount?.amount ?? 0,
        };

        totalNumberOfSecuritiesBySecClasses.push(newBySecClass);
      }
    });

    // These will subtracted (-=) from the amount, per security class name.
    amountToSubtract.forEach((amount) => {
      var totalBySecClass = totalNumberOfSecuritiesBySecClasses.find(
        (x) => x?.secClassName === amount?.secClassName
      );
      if (totalBySecClass) {
        totalBySecClass.amount -= amount?.amount ?? 0;
      } else {
        const newBySecClass: { secClassName: string; amount: number } = {
          secClassName: amount?.secClassName,
          amount: amount?.amount ?? 0,
        };

        totalNumberOfSecuritiesBySecClasses.push(newBySecClass);
      }
    });

    return totalNumberOfSecuritiesBySecClasses;
  };

  getNumberOfSecuritiesBySecClassAndTransactionType(
    transactions: StakeholderShareTransaction[],
    currentDateTime: Date,
    transactionTypes: string[]
  ): { secClassName: string; amount: number }[] {
    const filteredTransactions = transactions
      ?.filter((x) => transactionTypes.includes(x.type))
      ?.filter(
        (x) => new Date(x.transactionDate).getTime() < currentDateTime.getTime()
      );

    const totalNumberOfSecuritiesBySecClasses: {
      secClassName: string;
      amount: number;
    }[] = [];

    filteredTransactions.forEach((transaction) => {
      var totalBySecClass = totalNumberOfSecuritiesBySecClasses.find(
        (x) => x.secClassName === transaction?.holder?.securityClass
      );
      if (totalBySecClass) {
        totalBySecClass.amount += transaction?.holder.amount;
      } else {
        const newBySecClass: { secClassName: string; amount: number } = {
          secClassName: transaction?.holder.securityClass,
          amount: transaction?.holder.amount,
        };

        totalNumberOfSecuritiesBySecClasses.push(newBySecClass);
      }
    });

    return totalNumberOfSecuritiesBySecClasses;
  }

  getStakeHoldersUserNameByStrategyId(stakeholders: Stakeholder[], users: any[],strategyId: number,) {
    let applicableStakeHolders: Stakeholder[] = [];
    stakeholders.forEach((st) => {
      st.shares.forEach((share) => {
        if (strategyId === share.strategyId) {
          applicableStakeHolders.push(st)
        }
      });
      st.loanShares.forEach((share) => {
        if (strategyId === share.strategyId) {
          applicableStakeHolders.push(st)
        }
      });
      st.loans.forEach((loan) => {
        if (strategyId === loan.strategyId) {
          applicableStakeHolders.push(st)
        }
      });
      st.commitments.forEach((commitment) => {
        if (strategyId === commitment.strategyId) {
          applicableStakeHolders.push(st)
        }
      });
      st.certificates.forEach((cert) => {
        if (strategyId === cert.strategyId) {
          applicableStakeHolders.push(st)
        }
      });
    });
    var distinctStakeHolders= Array.from(
      new Map(
        applicableStakeHolders.map((st) => [
          `${st.ownerId}-${st.partitionKey}`, // Combine `ownerId` and `partitionKey` as a unique key
          st,
        ])
      ).values()
    );
     return distinctStakeHolders? users.filter(u => distinctStakeHolders.map(x=> x.ownerId).includes(u.entityId)).map(u => u.entityName).join(','):"";

    };

    getStrategyNameById = (strategyId: number) => {
      for (const strategy of this.strategyMappingSettings.values) {
        for (const combined of strategy.combined) {
          if (
            combined.values.some(
              (value: { entityId: number }) => value.entityId === strategyId
            )
          ) {
            return strategy.strategy;
          }
        }
      }
      return null;
    };
    getCombinedNameByStrategyId = (strategyId: number) => {
      const combined = this.strategyMappingSettings?.values
        .flatMap((x) => x.combined)
        .find((x) => x.values.some((value) => value.entityId === strategyId));
      return combined?.name;
    };
    getDetailedNameByStrategyId = (strategyId: number) => {
      const detailed = this.strategyMappingSettings?.values
        .flatMap((x) => x.combined)
        .flatMap((x) => x.values)
        .find((x) => x.entityId === strategyId);
      return detailed?.investment;
    };
    printStrategyCombinedName = (strategyId: number): string => {
      const combined = this.strategyMappingSettings?.values
        .flatMap((x) => x.combined)
        .find((x) => x.values.some((value) => value.entityId === strategyId));
      return `${combined.name} - ${strategyId}`;
    };
    getLoanType = (strategyId: number) => {
      const loanType = this.strategyMappingSettings.values
        .flatMap((x) => x.combined)
        .flatMap((x) => x.values)
        .find((x) => x.entityId === strategyId && x.type === "LOAN")?.loanType;

      return loanType;
    };
    getStrategyType = (strategyId: number) => {
      const strategyType = this.strategyMappingSettings.values
        .flatMap((x) => x.combined)
        .flatMap((x) => x.values)
        .find((x) => x.entityId === strategyId)?.type;

      return strategyType;
    };
    getStrategyPrices = (strategyId: number, type: string, priceType: string) => {
      const shareValuePrices = this.strategyMappingSettings.values
        .flatMap((x) => x.combined)
        .flatMap((x) => x.values)
        .find((x) => x.entityId === strategyId && x.type === type)?.prices;

      const normalPrices = shareValuePrices.filter((x) => x.type === "Normal");
      const pricesForPriceType = shareValuePrices.filter(
        (x) => x.type === priceType
      );

      // return the prices for the specified price type (which would be a security class name), or default to the prices with type === 'Normal'.
      return pricesForPriceType.length ? pricesForPriceType : normalPrices;
    };
    calculateActualValue = (
      stakeholders: Stakeholder[],
      selectedStrategyIds: number[],
      currentDateTime: Date
    ): number => {
      let sumSharePremiumRepresented = 0;
      let sumLoanTotalOutstandingAmount = 0;

    console.log(
      `--- START OF ACTUAL VALUE CALCULATION (${currentDateTime}) ---`
    );

    stakeholders.forEach((st) => {
      console.log(`Actual value calculation Stakeholder ${st.ownerId}:`);

        st.shares.forEach((share) => {
          if (!selectedStrategyIds.includes(share.strategyId)) {
            return;
          }
          var value = this.calculateActualShareValue(share, currentDateTime);
          sumSharePremiumRepresented += value;
        });

        // we group the loans by strategy id, because there can be multiple loans which are actually still the same loan.
        // The same loans are indicated by the same strategy id.
        // For calculating the actual value, we group the loans and take the loan with the latest startDate.
        const actualLoans = _.chain(st.loans)
          .groupBy((x) => x.strategyId)
          .map((loans, strategyId) => ({
            strategyId,
            loan: _.maxBy(loans, (loan) => new Date(loan.startDate)),
          }))
          .value()
          .map((x) => x.loan);

        actualLoans.forEach((loan) => {
          if (!selectedStrategyIds.includes(loan.strategyId)) {
            return;
          }
          const loanValue = this.calculateActualLoanValue(loan, currentDateTime);
          sumLoanTotalOutstandingAmount += loanValue;
        });
      });

    console.log(
      `Sum of shares: ${sumSharePremiumRepresented} + sum of loans: ${sumLoanTotalOutstandingAmount} = ${
        sumSharePremiumRepresented + sumLoanTotalOutstandingAmount
      }`
    );
    console.log(`--- END OF ACTUAL VALUE CALCULATION ---`);

      return sumSharePremiumRepresented + sumLoanTotalOutstandingAmount;
    };
    calculateActualShareValue = (
      share: StakeholderShare,
      currentDateTime: Date
    ): number => {
      const numberOfSecuritiesBySecClass =
        this.calculateNumberOfSecuritiesForTransactionsBySecClass(
          share.shareTransactions,
          currentDateTime
        );

      let actualShareValue = 0;

      numberOfSecuritiesBySecClass.forEach((amountBySecClass) => {
        const shareValuePrices = this.getStrategyPrices(
          share.strategyId,
          "SHARE",
          amountBySecClass.secClassName
        );

        if (shareValuePrices) {
          const sortedPrices = shareValuePrices
            .filter(
              (x) =>
                new Date(x.calculationDate).getTime() < currentDateTime.getTime()
            )
            .sort(
              (a, b) =>
                new Date(b.calculationDate).getTime() -
                new Date(a.calculationDate).getTime()
            );
          const currentShareValuePrice = _.first(sortedPrices)?.price ?? 0;

        console.log(
          `SHARE ${this.printStrategyCombinedName(share.strategyId)} = ${
            amountBySecClass.amount
          } securities * € ${currentShareValuePrice} (current price) for Security Class ${
            amountBySecClass.secClassName
          } = ${amountBySecClass.amount * currentShareValuePrice}`
        );

          const value = amountBySecClass.amount * currentShareValuePrice;

          actualShareValue += value;
        }
      });

      return actualShareValue;
    };
    calculateActualLoanValue = (
      loan: StakeholderLoan,
      currentDateTime: Date
    ): number => {
      const latestCalculation = _.first(
        loan.loanCalculations
          .filter(
            (x) => new Date(x.validFrom).getTime() < currentDateTime.getTime()
          )
          .sort(
            (a, b) =>
              new Date(b.validFrom).getTime() - new Date(a.validFrom).getTime()
          )
      );
      let loanValue = 0;

      if (latestCalculation) {
        if (latestCalculation.calculationSteps?.length) {
          const latestCalculationStep = _.first(
            latestCalculation.calculationSteps
              .filter(
                (x) => new Date(x.date).getTime() < currentDateTime.getTime()
              )
              .sort(
                (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
              )
          );

          loanValue = latestCalculationStep?.totalOutstandingAmount ?? 0;

        console.log(
          `LOAN ${this.printStrategyCombinedName(
            loan.strategyId
          )} = totalOutstandingAmount ${
            latestCalculationStep?.totalOutstandingAmount ?? 0
          }`
        );
      } else {
        loanValue = latestCalculation?.outstandingPrincipalAmount ?? 0;

        console.log(
          `LOAN ${this.printStrategyCombinedName(
            loan.strategyId
          )} = outstandingPrincipalAmount ${
            latestCalculation?.outstandingPrincipalAmount ?? 0
          }`
        );
      }
    }

    return loanValue;
  };
  calculateInitialValue = (
    stakeholders: Stakeholder[],
    selectedStrategyIds: number[],
    currentDateTime: Date
  ): number => {
    console.log(`--- START OF INITIAL VALUE CALCULATION ---`);

      let sumShares = this.calculateInitialShareValue(
        stakeholders,
        selectedStrategyIds,
        currentDateTime
      );
      let sumLoans = this.calculateInitialLoanValue(
        stakeholders,
        selectedStrategyIds,
        currentDateTime
      );

      const initialValue = sumShares + sumLoans;

    console.log(
      `Sum of shares: ${sumShares} + sum of loans: ${sumLoans} = ${initialValue}`
    );
    console.log(`--- END OF INITIAL VALUE CALCULATION ---`);

      return initialValue;
    };
    calculateInitialShareValue = (
      stakeholders: Stakeholder[],
      selectedStrategyIds: number[],
      currentDateTime: Date
    ): number => {
      let sumShares = 0;
      stakeholders.forEach((st) => {
        st.shares.forEach((share) => {
          if (!selectedStrategyIds.includes(share.strategyId)) {
            return;
          }

          const sumOfCapitalEquityAndPremiums = share.shareTransactions
            ?.filter(
              (x) => x.type === "ISSUE_SECURITY" || x.type === "CREATE_SECURITY"
            )
            ?.filter(
              (x) =>
                new Date(x.transactionDate).getTime() < currentDateTime.getTime()
            )
            ?.map((x) => x.holder)
            ?.reduce((sum, holder) => {
              return holder
                ? sum +
                holder.capitalEquitySubscribed +
                holder.sharePremiumSubscribed
                : sum;
            }, 0);

          const sumOfNumberOfShares = share.shareTransactions
            ?.filter(
              (x) =>
                x.type === "TRANSFER_SECURITY" && x.transferDirection === "IN"
            )
            ?.filter(
              (x) =>
                new Date(x.transactionDate).getTime() < currentDateTime.getTime()
            )
            ?.reduce((sum, transaction) => {
              return transaction?.holder
                ? sum +
                transaction?.holder.numberOfSecurities *
                transaction?.holder.purchasePrice
                : sum;
            }, 0);

          sumShares += sumOfCapitalEquityAndPremiums + sumOfNumberOfShares;
        });
      });

      return sumShares;
    };
    calculateInitialLoanValue = (
      stakeholders: Stakeholder[],
      selectedStrategyIds: number[],
      currentDateTime: Date
    ): number => {
      let sumLoans = 0;
      let sumCertificates = 0;

      stakeholders.forEach((st) => {
        // we group the loans by strategy id, because there can be multiple loans which are actually still the same loan.
        // The same loans are indicated by the same strategy id.
        // For calculating the initial value, we group the loans and take the loan with the earliest startDate.
        const initialLoans = _.chain(st.loans)
          .groupBy((x) => x.strategyId)
          .map((loans, strategyId) => ({
            strategyId,
            loan: _.minBy(loans, (loan) => new Date(loan.startDate)),
          }))
          .value()
          .map((x) => x.loan);

        initialLoans.forEach((loan) => {
          if (!selectedStrategyIds.includes(loan.strategyId)) {
            return;
          }

          const loanType = this.getLoanType(loan.strategyId);

          switch (loanType) {
            case "NV": {
              sumLoans += loan.principalAmount;

            console.log(
              `LOAN ${this.printStrategyCombinedName(
                loan.strategyId
              )} of type ${loanType}: principalAmount = ${loan.principalAmount}`
            );
            break;
          }
          case "Maatschap": {
            const loanShareTransactions = st.loanShares
              .filter((x) => x.strategyId === loan.strategyId)
              .flatMap((x) => x.shareTransactions);

              const totalNumberOfSecurities =
                this.calculateNumberOfSecuritiesForTransactions(
                  loanShareTransactions,
                  currentDateTime
                );
              sumLoans += totalNumberOfSecurities;

            console.log(
              `LOAN ${this.printStrategyCombinedName(
                loan.strategyId
              )} of type ${loanType}: number of shares = ${totalNumberOfSecurities}`
            );
            break;
          }
          default: {
            break;
          }
        }
      });

        st.certificates.forEach((cert) => {
          if (!selectedStrategyIds.includes(cert.strategyId)) {
            return;
          }

          if (cert.secClassName === "Z") {
            sumCertificates += cert.amount;

          console.log(
            `CERT ${this.printStrategyCombinedName(cert.strategyId)} = amount ${
              cert.amount
            }`
          );
        }
      });
    });

    console.log(
      `Sum of loans: ${sumLoans} + sum of certificates: ${sumCertificates} = ${
        sumLoans + sumCertificates
      }`
    );

      return sumLoans + sumCertificates;
    };
    calculateGrossDistributions = (
      stakeholders: Stakeholder[],
      selectedStrategyIds: number[],
      currentDateTime: Date
    ): number => {
      let grossDistributions = 0;

    console.log(`--- START OF GROSS DISTRIBUTIONS CALCULATION ---`);

    stakeholders.forEach((st) => {
      console.log(`Gross distributions calculation Stakeholder ${st.ownerId}:`);

        st.shares.forEach((share) => {
          if (!selectedStrategyIds.includes(share.strategyId)) {
            return;
          }

          share.shareTransactions
            ?.filter(
              (x) =>
                new Date(x.transactionDate).getTime() < currentDateTime.getTime()
            )
            ?.forEach((transaction) => {
              if (
                transaction.type !== "PAY_UP_UNPAID_SHARE" &&
                transaction.type !== "ADAPT_SHARE_CAPITAL" &&
                transaction.type !== "REVERT_TO_TRANSACTION"
              ) {
                if (transaction.transferDirection === "OUT") {
                  if (transaction.holder.purchasePrice) {
                    grossDistributions += Math.abs(
                      transaction.holder.purchasePrice *
                      transaction.holder.numberOfSecurities
                  );
                  console.log(
                    `DISTRIBUTIONS SHARE Transaction ${
                      transaction.transactionId
                    }: ${transaction.type} -> Gross dividend: Purchase Price ${
                      transaction.holder.purchasePrice
                    } * numberOfSecurities ${
                      transaction.holder.numberOfSecurities
                    } = ${Math.abs(
                      transaction.holder.purchasePrice *
                        transaction.holder.numberOfSecurities
                    )}`
                  );
                }
              } else {
                grossDistributions += transaction.holder.grossDividend;
                console.log(
                  `DISTRIBUTIONS SHARE Transaction ${transaction.transactionId}: ${transaction.type} -> Gross dividend: ${transaction.holder.grossDividend}`
                );
              }
            } else if (transaction.type !== "REVERT_TO_TRANSACTION") {
              grossDistributions +=
                transaction.holder.capitalPayouts +
                transaction.holder.sharePremiumPayouts;
              console.log(
                `DISTRIBUTIONS SHARE Transaction ${
                  transaction.transactionId
                }: ${transaction.type} -> CapitalPayouts: ${
                  transaction.holder.capitalPayouts
                } + SharePremiumPayouts: ${
                  transaction.holder.sharePremiumPayouts
                } = ${
                  transaction.holder.capitalPayouts +
                  transaction.holder.sharePremiumPayouts
                }`
              );
            }
          });
      });

        st.loans.forEach((loan) => {
          if (!selectedStrategyIds.includes(loan.strategyId)) {
            return;
          }

          const loanType = this.getLoanType(loan.strategyId);

        loan.loanCalculations
          ?.filter(
            (x) => new Date(x.validFrom).getTime() < currentDateTime.getTime()
          )
          ?.forEach((calculation) => {
            calculation.calculationSteps.forEach((calculationStep) => {
              if (calculationStep.type === "PAYMENT") {
                grossDistributions +=
                  calculationStep.amount + calculationStep.netInterest;
                console.log(
                  `DISTRIBUTIONS LOAN ${this.printStrategyCombinedName(
                    loan.strategyId
                  )} of type ${loanType}: Amount: ${
                    calculationStep.amount
                  } + NetInterest: ${calculationStep.netInterest} = ${
                    calculationStep.amount + calculationStep.netInterest
                  }`
                );
              }
            });
          });
      });

      console.log(`Total gross distribution: ${grossDistributions}`);
    });

    console.log(`--- END OF GROSS DISTRIBUTIONS CALCULATION ---`);

      return grossDistributions;
    };
    getOldestTransactionDate(
      stakeholders: Stakeholder[],
      selectedStrategyIds: number[],
      currentDateTime: Date
    ): Date {
      let oldestDate = new Date();

      stakeholders?.forEach((st) => {
        st.shares?.forEach((share) => {
          if (!selectedStrategyIds.includes(share.strategyId)) {
            return;
          }

          share.shareTransactions
            ?.filter(
              (x) =>
                new Date(x.transactionDate).getTime() < currentDateTime.getTime()
            )
            ?.forEach((transaction) => {
              oldestDate =
                new Date(transaction.transactionDate) < oldestDate
                  ? new Date(transaction.transactionDate)
                  : oldestDate;
            });
        });

        st.loanShares?.forEach((loanShare) => {
          if (!selectedStrategyIds.includes(loanShare.strategyId)) {
            return;
          }

          loanShare.shareTransactions
            ?.filter(
              (x) =>
                new Date(x.transactionDate).getTime() < currentDateTime.getTime()
            )
            ?.forEach((transaction) => {
              oldestDate =
                new Date(transaction.transactionDate) < oldestDate
                  ? new Date(transaction.transactionDate)
                  : oldestDate;
            });
        });
      });

      return oldestDate;
    }
    calculateNumberOfSecurities = (
      stakeholders: Stakeholder[],
      selectedStrategyIds: number[],
      currentDateTime: Date
    ): number => {
      let result = 0;

      stakeholders?.forEach((st) => {
        st.shares?.forEach((share) => {
          if (!selectedStrategyIds.includes(share.strategyId)) {
            return;
          }

          var value = this.calculateNumberOfSecuritiesForTransactions(
            share.shareTransactions,
            currentDateTime
          );
          result += value;
        });
      });

      return result;
    };
    calculatePaidValue = (
      stakeholders: Stakeholder[],
      selectedStrategyIds: number[],
      currentDateTime: Date
    ): number => {
      let result = 0;

      stakeholders?.forEach((st) => {
        st.shares?.forEach((share) => {
          if (!selectedStrategyIds.includes(share.strategyId)) {
            return;
          }

        share.shareTransactions
          ?.filter(
            (x) =>
              new Date(x.transactionDate).getTime() < currentDateTime.getTime()
          )
          .forEach((transaction) => {
            if (transaction.type === "CREATE_SECURITY") {
              if (transaction.holder.shareCapitalPaidUp) {
                result += transaction.holder.shareCapitalPaidUp;
                console.log(
                  `Calculate ${transaction.type} Paid up >> Strategy: ${share.strategyId} >> (shareCapitalPaidUp) ${transaction.holder.shareCapitalPaidUp}`
                );
              }
              if (transaction.holder.sharePremiumPaidUp) {
                result += transaction.holder.sharePremiumPaidUp;
                console.log(
                  `Calculate ${transaction.type} Paid up >> Strategy: ${share.strategyId} >> (sharePremiumPaidUp) ${transaction.holder.sharePremiumPaidUp}`
                );
              }
            } else if (
              transaction.type === "TRANSFER_SECURITY" &&
              transaction.transferDirection === "IN"
            ) {
              result +=
                transaction.holder.numberOfSecurities *
                transaction.holder.purchasePrice;
            } else if (transaction.type === "PAY_UP_UNPAID_SHARE") {
              result += transaction.holder.capitalIncreasePaid;
              result += transaction.holder.sharePremiumIncreasePaid;
            } else {
              if (transaction.holder.totalPaidUpAmount) {
                result += transaction.holder.totalPaidUpAmount;
                console.log(
                  `Calculate ${transaction.type} Paid up >> Strategy: ${share.strategyId} >> (totalPaidUpAmount) ${transaction.holder.totalPaidUpAmount}`
                );
              }
            }
          });
      });
    });

      return result;
    };
    calculateUnpaidValue = (
      stakeholders: Stakeholder[],
      selectedStrategyIds: number[],
      currentDateTime: Date
    ): number => {
      let result = 0;

      stakeholders?.forEach((st) => {
        st.shares?.forEach((share) => {
          if (!selectedStrategyIds.includes(share.strategyId)) {
            return;
          }

        share.shareTransactions
          ?.filter(
            (x) =>
              new Date(x.transactionDate).getTime() < currentDateTime.getTime()
          )
          .forEach((transaction) => {
            if (transaction.type === "CREATE_SECURITY") {
              if (transaction.holder.shareCapitalUnpaid) {
                result += transaction.holder.shareCapitalUnpaid;
                console.log(
                  `Calculate ${transaction.type} Unpaid >> Strategy: ${share.strategyId} >> (shareCapitalUnpaid) ${transaction.holder.shareCapitalUnpaid}`
                );
              }
              if (transaction.holder.sharePremiumUnpaid) {
                result += transaction.holder.sharePremiumUnpaid;
                console.log(
                  `Calculate ${transaction.type} Unpaid >> Strategy: ${share.strategyId} >> (sharePremiumUnpaid) ${transaction.holder.sharePremiumUnpaid}`
                );
              }
            } else if (transaction.type === "PAY_UP_UNPAID_SHARE") {
              result -= transaction.holder.capitalIncreasePaid;
              result -= transaction.holder.sharePremiumIncreasePaid;
            } else {
              if (transaction.holder.totalAmountToPay) {
                result += transaction.holder.totalAmountToPay;
                console.log(
                  `Calculate ${transaction.type} Unpaid >> Strategy: ${share.strategyId} >> (totalAmountToPay) ${transaction.holder.totalAmountToPay}`
                );
              }
            }
          });
      });
    });

      return result;
    };
    calculateCashflow(transaction: StakeholderShareTransaction): number {
      switch (transaction.type) {
        case "CREATE_SECURITY":
          return (
            transaction.holder.shareCapitalPaidUp +
            transaction.holder.sharePremiumPaidUp
          );
        case "ISSUE_SECURITY":
          return transaction.holder.totalPaidUpAmount;
        case "TRANSFER_SECURITY":
          return (
            transaction.holder.numberOfSecurities *
            transaction.holder.purchasePrice
          );
        case "PAY_DIVIDENDS":
          return transaction.holder.grossDividend * -1;
        case "ADAPT_SHARE_CAPITAL":
          return (
            (transaction.holder.capitalPayouts +
              transaction.holder.sharePremiumPayouts) *
            -1
          );
        case "PAY_UP_UNPAID_SHARE":
          return (
            (transaction.holder.capitalIncreasePaid +
              transaction.holder.sharePremiumIncreasePaid)
          );
        default:
          return 0;
      }
    }
    getShareValuePrice(
      strategyId: number,
      date: Date,
      translatedSecClassName: string
    ) {
      const prices = this.getStrategyPrices(
        strategyId,
        "SHARE",
        translatedSecClassName
      );
      const sortedPrices = prices
        .filter((x) => new Date(x.calculationDate).getTime() < date.getTime())
        .sort(
          (a, b) =>
            new Date(b.calculationDate).getTime() -
            new Date(a.calculationDate).getTime()
        );
      const currentShareValuePrice = _.first(sortedPrices)?.price;
      return currentShareValuePrice;
    }
    doesStrategyApply(strategyId: number, stakeholders: Stakeholder[]) {
      let itDoes = false;

      stakeholders?.forEach((st) => {
        var certificates = st.certificates.filter(
          (x) => x.strategyId === strategyId
        );
        if (certificates?.length) {
          itDoes = true;
        }

        var commitments = st.commitments.filter(
          (x) => x.strategyId === strategyId
        );
        if (commitments?.length) {
          itDoes = true;
        }

        var loanShares = st.loanShares.filter((x) => x.strategyId === strategyId);
        if (loanShares?.length) {
          itDoes = true;
        }

        var shares = st.shares.filter((x) => x.strategyId === strategyId);
        if (shares?.length) {
          itDoes = true;
        }

        var loans = st.loans.filter((x) => x.strategyId === strategyId);
        if (loans?.length) {
          itDoes = true;
        }
      });

      return itDoes;
    }

    getShareValuePricesPerSecurityClass(
      strategyId: number,
      stakeholders: Stakeholder[],
      currentDateTime: Date,
      securityClasses: SecurityTypeMappingSetting[]
    ): { [key: string]: number } {
      const shareValuePricesPerSecurityClass: { [key: string]: number } = {};
      stakeholders.forEach((st) => {
        st.shares.forEach((share) => {
          if (strategyId !== share.strategyId) {
            return;
          }

          share.shareTransactions
            ?.filter(
              (x) =>
                new Date(x.transactionDate).getTime() < currentDateTime.getTime()
            )
            ?.forEach((transaction) => {
              const currentShareValuePrice = this.getShareValuePrice(
                share.strategyId,
                currentDateTime,
                this.translateSecurity(
                  share.strategyId,
                  securityClasses,
                  transaction.holder.securityClass
                )
              );

              if (
                !shareValuePricesPerSecurityClass[
                transaction.holder.securityClass
                ]
              ) {
                shareValuePricesPerSecurityClass[
                  transaction.holder.securityClass
                ] = currentShareValuePrice;
              }
            });
        });
      });

      return shareValuePricesPerSecurityClass;
    }

    translateSecurity(
      strategyId: number,
      securityClasses: SecurityTypeMappingSetting[],
      security: string
    ): string {
      return (
        securityClasses
          .filter(x => x.strategyIds.includes(strategyId))
          .flatMap((x) => x.mappings)
          .find((x) => x.original === security)?.translated ?? security
      );
    }
  }
export default StrategyCalculator;
